import React from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/solid'
import { twMerge } from 'tailwind-merge'

import BlogHome from '@/components/blog'
import BlogCarouselCard from '../cards/blog-card'

const mainSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToScroll: 2,
  arrows: true,
  swipeToSlide: true,
  draggable: true,
}

function NextArrow({ currentSlide, slideCount, cardsToShow, ...props }) {
  return (
    <button
      {...props}
      className={twMerge(
        'moz-css absolute !inline-flex items-center justify-center left-0 bg-white/90 border border-gray-200 rounded-full -bottom-20 w-14 h-14 translate-x-[70px]',
        currentSlide !== slideCount - cardsToShow &&
          'text-blue-600 border-blue-200',
        currentSlide === slideCount - cardsToShow && 'text-gray-400 ',
      )}
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - cardsToShow ? true : false}
      type="button"
    >
      <ArrowRightIcon width={20} height={29} />
    </button>
  )
}

NextArrow.propTypes = {
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number,
  cardsToShow: PropTypes.number,
}

NextArrow.defaultProps = {
  currentSlide: 0,
  slideCount: 0,
}

function PrevArrow({ currentSlide, slideCount, ...props }) {
  return (
    <button
      {...props}
      className={twMerge(
        'moz-css absolute !inline-flex items-center justify-center left-0 bg-white/90 border border-gray-200 rounded-full  -bottom-20 w-14 h-14 translate-x-[0px]',
        currentSlide !== 0 && 'text-blue-600 border-blue-200',
        currentSlide === 0 && 'text-gray-400 ',
      )}
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <ArrowLeftIcon width={20} height={20} />
    </button>
  )
}

PrevArrow.propTypes = {
  currentSlide: PropTypes.number,
  slideCount: PropTypes.number,
}

const BlogsCarousel = ({ data }) => {
  const cardsToShow = {
    desktop: 3.28,
    bigTablet: 2.96,
    tablet: 1.97,
    mobile: 1,
  }

  // Adjust your settings and breakpoints as needed
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 2,
    arrows: true,
    swipeToSlide: true,
    draggable: true,
    slidesToShow: 3, // Adjust the number of slides to show
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 2, // Adjust for different breakpoints
          nextArrow: <NextArrow />,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1, // Adjust for different breakpoints
          nextArrow: <NextArrow />,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1, // Adjust for different breakpoints
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <span
      className="relative overflow-hidden"
      style={{ webkitTransition: 'translate3d(0,0,0)' }}
    >
      <Slider {...settings}>
        {data &&
          data?.map((item, index) => (
            <BlogCarouselCard key={index} data={item} />
          ))}
      </Slider>
    </span>
  )
}

BlogsCarousel.propTypes = {
  data: PropTypes.array.isRequired,
}

export default BlogsCarousel
