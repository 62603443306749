import { ArrowRightIcon } from '@heroicons/react/24/outline'
import ReactHtmlParser from 'html-react-parser'
import moment from 'moment'
import Image from 'next/image'
import NextLink from 'next/link'

const BlogCarouselCard = ({ data }) => {
  console.log({ data }, 'checking...')
  const { title, slug, date, featuredImage, excerpt, author } = data || {}
  return (
    <div className="relative flex flex-col rounded-md sm:mr-8">
      <a href={`/blog/${slug}`} passHref>
        <div className="relative h-[280px] overflow-hidden bg-gray-200 rounded-md group-hover:opacity-75">
          <Image
            width={1000}
            height={1000}
            src={
              featuredImage?.node?.sourceUrl
                ? featuredImage?.node?.sourceUrl
                : 'abc.webp'
            }
            alt="post-image"
            unoptimized
            // fill
            className="object-cover w-full h-full"
          />
          <div className="absolute bottom-0 flex flex-col justify-center w-full h-20 px-5 space-y-0.5 text-white bg-black/50 backdrop-blur-md transform-gpu">
            <h3 className="text-sm font-semibold">{author?.node?.name}</h3>
            <h3 className="text-sm font-normal">
              {moment(date).format('DD MMM YYYY')}
            </h3>
          </div>
        </div>
      </a>

      <div className="flex-1 py-3">
        <div className="flex flex-col justify-between flex-1 my-6 ">
          <div className=''>
            <h3 className="text-xl text-start line-clamp-3 leading-lg-32">
              {title}
            </h3>
            <div className="mt-2 text-base text-gray-600 h-[150px] overflow-hidden !line-clamp-3 text-start">
              {ReactHtmlParser(excerpt)}
            </div>
          </div>
          <div className="group">
            <NextLink
              href={data?.link ? data?.link : `/blog/${slug}`}
              passHref
              className="flex items-center gap-2 mt-5 text-sm font-semibold text-blue-600 transition-colors group-hover:text-blue-500 "
            >
              <a href="" className='z-10 absolute'>
                Read post
                <ArrowRightIcon className="w-4 h-4 text-blue-600 transition-colors -rotate-45 group-hover:text-blue-500" />
              </a>
            </NextLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogCarouselCard
