import React, { useState } from 'react'
import styles from './index.module.css'
import cn from 'classnames'
import { useRouter } from 'next/router'
import ReactPaginate from 'react-paginate'
import PropTypes from 'prop-types'
import CardArticleSummary from '../../../common/cards/card-article-summary'
import Title from '../../../common/title'
import Link from 'next/link'
import Image from 'next/image'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import Container from '../../../common/container'
import classNames from 'classnames'
import { BlogsCarousel } from '@/components/ui/carousels'
import Dots from '../../../common/svgs/dots'
import BlogPost from 'components/storyblok/blog-post'
import Blog from '@/pages/blog'

const gradientBg = '/assets/images/docs/articles/gradient-bg.svg'

const CollectionsSection = ({ allPost, postData }) => {
  const Options = {
    sec7Subtitle: 'Stay in loop',
    sec7Title: 'The latest updates on Relysia Ecosystem',
    sec7Desc:
      'Catch up with our latest blogs and insights into the Relysia ecosystem.',
    sec6Btn: {
      title: 'View All Blogs',
    },
  }
  // console.log('allPost data:', allPost)

  const router = useRouter()
  const [page, setPage] = useState(1)

  const perPage = 7

  const handlePageClick = ({ selected }) => {
    const path = router.pathname
    const query = router.query

    query.page = selected + 1
    router.push({
      pathname: path,
      query: query,
    })

    setPage(() => selected + 1)
  }

  return (
    <section className={cn(styles.base, classNames)} style={{ width: '100vw' }}>
      <Container>
        <Dots sides={{ top: '220px', left: '-40px' }} />
        <div className="relative pr-4 sm:mx-auto pb-14 md:pb-20 max-w-7xl lg:mt-24 sm:pr-6 lg:px-8">
          <div className="space-y-4 text-left lg:text-left">
            <Title optimised heading="h5" classNames={'-mb-10 text-left'}>
              Latest Blogs
            </Title>
            <Title heading="h2" classNames="pt-10">
              <br />
              {Options.sec7Title}
            </Title>
            {/* <p className={styles.p}>{Options.sec7Desc}</p> */}
          </div>
          <div className="mt-10 lg:mt-0 lg:absolute top-0 right-8">
            <Link
              href="/blog"
              className="mt-10 lg:mt-0 lg:absolute top-0 right-8"
            >
              <a>
                <button className="bg-gradient w- rounded-xl flex items-center justify-between gap-2 sm:min-w-[174px] py-3 px-5 text-base text-white border-transparent">
                  {Options.sec6Btn.title}
                  <ArrowRightIcon className="w-5 h-5 text-white -rotate-45" />
                </button>
              </a>
            </Link>
          </div>
          <div className="pb-24 pt-10 overflow-hidden mx-auto w-full max-w-7xl 3xlg:min-w-[1364px]">
            {allPost?.length > 0 ? (
               <BlogsCarousel data={allPost} />
              // <div>aaa</div>
            ) : (
              <div className="flex justify-center items-center h-60 text-xl text-gray-500">
                No blogs available.
              </div>
            )}
          </div>
        </div>
      </Container>
    </section>
  )
}

export default CollectionsSection
